import React from 'react'

import AlliedBox from '../molecules/AlliedBox'
import Slider from 'react-slick/lib/slider';
import Icon from '../atoms/Icon';

function AlliancesGallery({
  alliedBoxType,
  itemsPerLine,
  limit,
  termsOnModal,  
  list,
  termText,
  termLink
}) {

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return <button className="custom-next-arrow" onClick={onClick}><Icon name='nextArrow' /></button>;
  };
  
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return <button className="custom-prev-arrow" onClick={onClick}><Icon name='prevArrow' /></button>;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    // centerPadding: '40px 0px 0px',
    initialSlide: 1, 
    pauseOnHover: true,
    focusOnSelect: true,
    nextArrow: <CustomNextArrow />, 
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {                    
          infinite: true,
          dots: true,          
          autoplay: true,     
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false,
          autoplay: true,
          rows:1
        },
      }
    ]
  }

  return (
    <div className={`mc-alliances-container mc-alliances-column-${itemsPerLine}`}>
      {list && list.length > 1 ?
        <div className="mc-alliances-container__items">
          <Slider {...settings}>
          {list.filter((item, i) => {

            return limit ? i < limit ? true : false : true
          }).map((item, index) => {

            return (
              <div key={index} className={`mc-alliances-container__items--item type-${alliedBoxType}`}>
                <AlliedBox
                  type={alliedBoxType}
                  allied={item}
                  termLink={termLink}
                  termText={termText}
                  termsOnModal={termsOnModal}
                  termsData={item.termsData}                  
                />
              </div>
            )
          })}
      </Slider>
        </div>

        :
        <div className="mc-alliances-container__items">

          {list.filter((item, i) => {

            return limit ? i < limit ? true : false : true
          }).map((item, index) => {

            return (
              <div key={index} className={`mc-alliances-container__items--item type-${alliedBoxType}`}>
                <AlliedBox
                  type={alliedBoxType}
                  allied={item}
                  termLink={termLink}
                  termText={termText}
                  termsOnModal={termsOnModal}
                  termsData={item.termsData}                  
                />
              </div>
            )
          })}
        </div>
      }

    </div>
  )
}

export default AlliancesGallery