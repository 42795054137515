import React, { useEffect, useState } from 'react'
import Container from '../../layouts/Container/Container'

const Preheader = ({closePreHeader, setClosePreHeader}) => {
 
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }, [])
    return (
        <div className={`Preheader ${!closePreHeader ? 'hide':''}`}>
            <Container className={'Preheader'}>
                <div className='content-preheader'>
                    <button onClick={() => setClosePreHeader(!closePreHeader)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.21967 6.90522C6.51256 6.61232 6.98744 6.61232 7.28033 6.90522L17.7803 17.4052C18.0732 17.6981 18.0732 18.173 17.7803 18.4659C17.4874 18.7588 17.0126 18.7588 16.7197 18.4659L6.21967 7.96588C5.92678 7.67298 5.92678 7.19811 6.21967 6.90522Z" fill="#B4B4B4" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.7803 6.90522C18.0732 7.19811 18.0732 7.67298 17.7803 7.96588L7.28033 18.4659C6.98744 18.7588 6.51256 18.7588 6.21967 18.4659C5.92678 18.173 5.92678 17.6981 6.21967 17.4052L16.7197 6.90522C17.0126 6.61232 17.4874 6.61232 17.7803 6.90522Z" fill="#B4B4B4" />
                        </svg>
                    </button>
                    <p>¡Recuerda que los bonos son limitados y puedes redimirlos hasta agotar stock, redime el tuyo si eres ganador y <br /> disfruta de tu premio!</p>
                </div>
            </Container>
        </div>
    )
}

export default Preheader
