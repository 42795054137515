import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import { getMaximumBlock } from '../../utils/functions'
import Title from '../atoms/Title'
import AwardStock from '../molecules/AwardStock'
import Tabs from '../molecules/Tabs'
import SectionSpecialAward from '../organisms/SectionSpecialAward'
import SectionTyc from '../organisms/SectionTyc'
// import CustomModal from '../molecules/CustomModal'

function Awards() {
  const [awards, setAwards] = useState(false)
  const [canRedem, setCanRedem] = useState(false)
  const { context } = useContext(StateController)
  const [redemtions, setRedemtions] = useState(false)
  // const [warningModal, setWarningModal] = useState(true);



  useEffect(() => {
    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      )
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
    }
    if (context && context.redemtions) {
      setRedemtions(context.redemtions)
    }
  }, [context])
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      {/* {canRedem && <CustomModal
        title="Así redimes tu bono en Almacenes Tía:"
        desc={`<p class='text-start'><strong class='mr-2'>1. </strong> Descarga el PDF.</p> <p class='text-start'><strong class='mr-2'>2. </strong> Haz clic en “Completar datos” y sigue los pasos.</p> <p class='text-start'><strong class='mr-2'>3. </strong> ¡Y listo! En 4 días hábiles recibirás el código para redimir tu bono.</p>`}
        cta="Entendido"
        image="img_Modal_TIA.png"
        show={warningModal} setShowModal={setWarningModal}
        pushInfoTIA        
      />} */}
      <section className='mc-page section-awards awards slow_ani'>
        <Container>
          <article className='text-center'>

            <Title
              text={<>Tus bonos son limitados, ¡úsalos ya!</>}
              tag={'h2'}
              className={'text-center'}
            />
            <p className='mb-4 size-18 font-thin'>
              Cumple tu meta, redime tus bonos y recuerda que todas tus compras participan en el sorteo del auto 0 km.
            </p>

            <AwardStock />

          </article>
        </Container>
      </section>

      <section>
        <Container className={'tab-premios'}>
          <article>
            <Title
              text={<>¿Cuál de estos bonos digitales te gustaría redimir?</>}
              tag={'h2'}
              className={'size-24 text-center'}
            />
            <p className='size-20 text-center mb-4 font-thin'>
              Explora todas las posibilidades:
            </p>

            <Tabs
              awards={awards}
              canRedem={canRedem}
              redemtions={redemtions}
              context={context}
            // setTabSelected={setTabSelected}
            />



          </article>
        </Container>
      </section>

      <SectionSpecialAward />

      <SectionTyc />
    </>
  )
}
export default Awards