import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { connect } from 'react-redux'

// pages
import Login from "./components/pages/Login"
import Progress from "./components/pages/Progress"
import Awards from "./components/pages/Awards"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
import PremioMayor from './components/pages/PremioMayor'
// layout
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'

// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'

import {
  gtUser,
  gtStaticContent
} from './utils/helpers'
import { sendDataLayer } from './utils/functions'
import { doRedeem } from './utils/helpers'

import './styles/main.scss'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)
  const [firstLogin, setFirstLogin] = useState(false);

  const [awardSelected, setAwardSelected] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showSuccessRedemtion, setShowSuccessRedemtion] = useState(false)
  const [quantumName, setQuantumName] = useState('')
  const [quantumDoc, setQuantumDoc] = useState('')
  const [department, setDepartment] = useState(false)
  const [city, setCity] = useState(false)
  const [site, setSite] = useState(false)
  const [showErrorAuth, setShowErrorAuth] = useState(false)
  const [authDate, setAuthDate] = useState('')

  const home_page = '/premio-mayor'
  const pages = [
    { page: 'premio-mayor', title: 'Premio mayor', icon: 'premio_mayor', sublevel: false, component: <PremioMayor privatePage /> },
    { page: 'progreso', title: 'Progreso', icon: 'progreso', sublevel: false, component: <Progress privatePage /> },
    { page: 'premios', title: 'Bonos', icon: 'premios', sublevel: false, component: <Awards privatePage /> },
    {
      page: 'mas', title: 'Más info', icon: 'mas', sublevel: true, children: [
        { page: 'terminos-y-condiciones', title: 'Términos y condiciones', icon: 'tyc_submenu', component: <Terms /> },
        { page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', icon: 'faqrs_submenu', component: <Faqs /> }
      ]
    }
  ]

  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/'
  }
  console.log('context', context)
  useEffect(() =>{
    if (context?.firstTime) {
      setTimeout(()=>{
        setFirstLogin(context?.firstTime) 
      },1000)     
    }
  },[context])

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout(() => {
        localStorage.removeItem('userNotFound')
      }, 3000)
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)
      sendDataLayer({datalayer:{ event: "success_login_auto", userId: context?.user?.idmask, segment: context?.user?.segment}, repeat: false})
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', ''))
        const public_children_urls = pages.find((page) => page.sublevel)

        if (public_url) {
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/'

        } else if (public_children_urls) {

          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', ''))
          if (public_child_url?.component?.props?.privatePage) navigate('/')
        }
      }
    }
  }, [searchParams, error])

  useEffect(() => {

    if (token !== '') {
      gtUser(token).then((user) => {

        if (user?.data) {
          setContext(user.data)

          if (window.location.pathname !== home_page && window.location.pathname === '/') {
            window.location.href = home_page
          }

        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/'
        }
      }).catch(() => navigate('/'))

    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])

  const handlerModal = () => {
    if (showConfirm) {
      sendDataLayer({ datalayer: { event: 'cancel_bond', product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index }, repeat: true })
    }
    setShowConfirm(!showConfirm)

  }

  const handlerRedemtion = async () => {
    setRedemtionLoading(true)
    setShowErrorAuth(false)

    if (awardSelected.canRedem) {
      doRedeem(token, awardSelected.price, awardSelected.id, authDate).then((data) => {
        setRedemtionLoading(false)
        setShowConfirm(false)

        if (data && data.status === 200 && data.file) {
          setShowSuccessRedemtion(true)
          sendDataLayer({ datalayer: { event: "redeem_bond", product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index }, repeat: false })


          setTimeout(() => {
            window.location.href = 'premios'
          }, 5000)
        } else {

          console.log("Error redemtion!", data)

          if (data.status === 304) {
            setShowConfirm(true)
            setShowErrorAuth(true)
          } else {
            setShowErrorAuth(false)
            sendDataLayer({ datalayer: { event: 'exhausted_bond', product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index }, repeat: true })            
            setShowErrorRedemtion(true)
          }        

        }
      }).catch(error => {
        console.log("ERROR ON REDENTION:", error)
      })

    } else {
      setRedemtionLoading(false)
      setShowConfirm(false)
      setShowErrorRedemtion(true)
    }
  }

  return (
    <StateController.Provider
      value={
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages,
          awardSelected: awardSelected,
          setAwardSelected: setAwardSelected,
          handlerRootModal: handlerModal
        }
      }
    >
      <CustomModal
        title="El código de registro no es válido"
        desc="<p class='mb-3'>Por favor, comprueba la información enviada a tu correo e inténtalo de nuevo.</p><p class='size-16'>*Es importante el uso de las mayúsculas y minúsculas de tu código.</p>"
        cta="Aceptar"
        icon={false}
        image={'error.png'}
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Cerrar',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />

      <CustomModal
        show={showConfirm}
        setShowModal={setShowConfirm}
        redemtion={true}
        image={awardSelected.image}
        price={`USD ${awardSelected.price}`}
        name={awardSelected.name}
        location={awardSelected?.location}
        brand_id={awardSelected?.brand_id}
        quantumName={quantumName}
        quantumDoc={quantumDoc}
        setQuantumName={setQuantumName}
        setQuantumDoc={setQuantumDoc}
        department={department}
        city={city}
        site={site}
        setDepartment={setDepartment}
        setCity={setCity}
        setSite={setSite}
        token={token}
        awardSelected={awardSelected}
        showErrorAuth={showErrorAuth}
        primaryAction={
          {
            action: handlerRedemtion,
            buttonText: "Descargar",
            loading: redemtionLoading,
            setAuthDate: setAuthDate
          }
        }
        secondaryAction={
          {
            action: handlerModal,
            buttonText: "Cancelar"
          }
        }
      // terms={
      //   {
      //     termsText: "Términos y condiciones",
      //     termsLink: "/terminos-y-condiciones"
      //   }
      // }
      />
      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />
      <CustomModal
        title="Bono descargado con éxito"
        desc={`¡Aprovecha tu bono y sigue adelante! Acumula el doble de oportunidades para participar por el Citroën C-Elysée 0 km usando tu tarjeta para pagos recurrentes en plataformas de entretenimiento.`}
        cta="Terminar"
        image="done.png"
        show={showSuccessRedemtion} setShowModal={setShowSuccessRedemtion}
        showSuccessRedemtion={showSuccessRedemtion}
      />

{
        (firstLogin && context) && 
          <CustomModal
            title="¡Registro exitoso!"
            desc={`Cumple tu meta, gana un bono digital, y participa por un auto último modelo.`}
            cta="Aceptar"
            image="register.png"
            show={firstLogin} setShowModal={setFirstLogin}        
          />
      }

      <div className={`App ${modalOpen ? 'modalOpen' : ''} ${loggedIn ? 'loggedIn' : 'noLoggedIn'}`}>
        <Header
          headerType={1}
          logo="logo.png"
          items={pages}
          notifications={
            [
              {
                id: 1,
                title: "Notification",
                content: "html content",
                status: 0
              }
            ]
          }
          loggedIn={loggedIn}
          logOutAction={logOutAction}
        />

        <Routes>
          <Route path="/" element={<Login autologin={autologinPreloader} hash={searchParams.get('utm_web')} />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          <Route path="*" element={<NotFound loggedIn={loggedIn} />} />
        </Routes>

        <Footer
          footerType={1}
          footerCardsImg="logos-bankard-mc.png"
          secondaryFooterImg="footer_cards.png"
          bankFooterImg="bank-footer.png"
          links={
            [
              {
                link: "/terminos-y-condiciones",
                text: "Términos y condiciones"
              },
              {
                link: "/preguntas-frecuentes",
                text: "Preguntas frecuentes"
              }
            ]
          }
          content="<p>*Válido del 16 de septiembre de 2024 al 11 de noviembre de 2024 y solo aplica para el territorio ecuatoriano. Recuerde que por su seguridad Banco Bolivariano nunca solicita a través de este medio información confidencial o financiera como usuarios y claves de acceso a nuestros canales, ni números de productos como cuentas, números de tarjetas de crédito o similares. </p> <br />  <p>*Esta es una campaña oficial del Banco Bolivariano.</p>"
          copyright="© Copyright 2024. Todos los derechos reservados"
        />

      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)